
import Vue from "vue"
import { MAP_LANGUAGE_CODES } from "~/constants/locale"

export default Vue.extend({
  name: "TheFooterContainer",

  props: {
    page: {
      type: Object as () => Record<string, any>,
      required: true
    },
    isRebrand: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    MAP_LANGUAGE_CODES(): typeof MAP_LANGUAGE_CODES {
      return MAP_LANGUAGE_CODES
    }
  }
})
