import { render, staticRenderFns } from "./TheFooterDE.vue?vue&type=template&id=8fd09d52&lang=pug"
import script from "./TheFooterDE.vue?vue&type=script&lang=ts"
export * from "./TheFooterDE.vue?vue&type=script&lang=ts"
import style0 from "./TheFooterDE.vue?vue&type=style&index=0&id=8fd09d52&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LocalisedTrustpilotFooter: require('/app/components/localised/TrustpilotFooter.vue').default,Icon: require('/app/components/vantone/Icon.vue').default,PhoneNumber: require('/app/components/generic/PhoneNumber.vue').default})
