import Vue from "vue"
import tailwindConfig from "~/tailwind.config.js"

interface BreakpointsData {
  queryList: Record<string, boolean>
  listeners: Record<string, MediaQueryList>
  breakpoints: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    xxl: string
    rebrandLg: string
    rebrandNav: string
  }
}

// TODO: Candidate for util class, but contain beforeDestroy hook, thus requres changing logic
export default Vue.extend({
  name: "Breakpoints",

  data(): BreakpointsData {
    return {
      queryList: {},
      listeners: {},
      breakpoints: tailwindConfig.theme.screens
    }
  },

  beforeDestroy(): void {
    for (const mqName in this.listeners) {
      this.listeners[mqName].removeListener(this.listenMedia)
    }
  },

  methods: {
    getMediaQuery(name: string, useMax = false): string {
      const breakpoint = this.breakpoints[name]

      if (useMax) {
        const number = parseFloat(breakpoint)
        const unit = breakpoint.replace(number, "")
        const substract = unit === "em" ? 0.0635 : 1
        return `(max-width: ${number - substract}${unit})`
      }

      return `(min-width: ${breakpoint})`
    },

    listenMedia(val: MediaQueryListEvent): void {
      Vue.set(this.queryList, val.media, val.matches)
    },

    Breakpoints_matchMedia(name: string, useMax = false): boolean {
      let mq: MediaQueryList
      const mqName = this.getMediaQuery(name, useMax)

      if (typeof window !== "undefined") {
        if (this.queryList[mqName]) {
          return this.queryList[mqName]
        } else {
          mq = window.matchMedia(mqName)
          mq.addListener(this.listenMedia)
          this.listeners[mqName] = mq
          return window.matchMedia(this.getMediaQuery(name, useMax)).matches
        }
      } else return true
    }
  }
})
