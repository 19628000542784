import Vue from "vue"
import { getYear } from "date-fns"
import { mapGetters } from "vuex"
import Breakpoints from "~/components/base/breakpoints"
import { GET_USER } from "~/store/auth/getters"

interface ComponentData {
  currentYear: number
}

export default Vue.extend({
  name: "FooterBase",
  extends: Breakpoints,

  props: {
    phone: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    isRebrand: {
      type: Boolean,
      default: false
    }
  },

  data(): ComponentData {
    return {
      currentYear: getYear(new Date())
    }
  },

  computed: {
    ...mapGetters("auth", {
      user: GET_USER
    }),

    emailHref(): string {
      return `mailto:${this.email}`
    }
  }
})
