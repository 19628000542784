
import Vue from "vue"
import TrustpilotBase from "~/components/base/trustpilotBase"

export default Vue.extend({
  name: "Trustpilot",
  extends: TrustpilotBase,

  computed: {
    ratingString(): string {
      const rating = this.$t("trustpilot.rating").toString() + " Trustpilot"
      return rating
        .replace("RATING", `${this.trustpilotRating}`)
        .replace("REVIEWCOUNT", (this.trustpilotCustomers as number).toString())
    },
    localeLink(): string {
      const trustPilotLinks = {
        en: "https://uk.trustpilot.com/review/anyvan.com",
        es: "https://es.trustpilot.com/review/anyvan.es",
        fr: "https://fr.trustpilot.com/review/anyvan.fr",
        de: "https://de.trustpilot.com/review/anyvan.de",
        it: "https://it.trustpilot.com/review/anyvan.it"
      }
      if (!this.$i18n.locale) {
        return trustPilotLinks.en
      }
      return trustPilotLinks[this.$i18n.locale]
    }
  }
})
