
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"

export default Vue.extend({
  name: "NavCallUs",
  computed: {
    ...mapGetters("phone-number", {
      getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    })
  },
  methods: {
    handleCalUsClick(phoneNumber: string, extension?: string) {
      this.$snowplow.trackCallUs({
        phoneNumber,
        label: "navigation_button",
        action: "click",
        extension
      })
      this.$emit("hide-other-menus")
    }
  }
})
