
import Vue from "vue"
import { mapGetters } from "vuex"
import { LiveChatWidget } from "@livechat/widget-vue/v2"
import { getAttributes } from "~/utils/block"
import { GET_INVENTORY } from "~/store/pages/getters"
import { SET_INVENTORY, SET_WINDOW_WIDTH } from "~/store/pages/actions"
import { GET_CREDIT_CODE_VALUE } from "~/store/credit-code/getters"
import { URL_PARAM_HIDE_ADMIN } from "~/constants/booking-insights"

interface ComponentData {
  blocks: Block[],
  hideAdmin: boolean
}

export default Vue.extend({
  name: "PageLayoutRebrand",

  components: {
    LiveChatWidget
  },

  props: {
    page: {
      type: Object as () => Page,
      required: true
    }
  },

  data(): ComponentData {
    return {
      blocks: [],
      hideAdmin: false
    }
  },

  computed: {
    ...mapGetters("pages", {
      getInventory: GET_INVENTORY
    }),
    ...mapGetters("credit-code", {
      creditCodeValue: GET_CREDIT_CODE_VALUE
    }),

    shouldDisplayLiveChat(): boolean {
      return Boolean(
        this.$config.liveChatLicense && this.getInventory === false && this.$i18n.locale !== "es"
      )
    }
  },

  created() {
    this.blocks = [...this.page?.blocks]
    this.hideAdmin = this.$route.query[URL_PARAM_HIDE_ADMIN] === "true"
  },

  beforeMount() {
    this.$store.dispatch(`pages/${SET_WINDOW_WIDTH}`, window.innerWidth)
  },

  mounted() {
    if (this.getInventory === undefined) {
      this.$store.dispatch(`pages/${SET_INVENTORY}`, false)
    }
  },

  methods: {
    getAttributes
  }
})
