
import Vue from "vue"

export default Vue.extend({
  name: "AnyVanLogo",

  props: {
    color: {
      type: String,
      default: "white"
    },
    width: {
      type: Number,
      default: 140
    },
    height: {
      type: Number,
      default: 24
    },
    full: {
      type: Boolean,
      default: true
    },
    withoutLink: {
      type: Boolean
    }
  },

  computed: {
    homeLink(): string {
      switch (this.$i18n.locale) {
        case "en":
          return this.$config.v4Host
        case "es":
          return this.$config.v4Host + "/es"
        case "fr":
          return this.$config.v4Host.replace(".com", ".fr")
        case "de":
          return this.$config.v4Host.replace(".com", ".de")
        case "it":
          return this.$config.v4Host.replace(".com", ".it")
        default:
          return this.$config.v4Host
      }
    }
  }
})
