
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_FW_TOKEN, GET_FW_TOKEN_TIMESTAMP } from "~/store/auth/getters"

export default Vue.extend({
  name: "LoginForm",

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters("auth", {
      nonce: GET_FW_TOKEN,
      nonceTimestamp: GET_FW_TOKEN_TIMESTAMP
    })
  },

  methods: {
    closeForm(): boolean {
      this.$emit("input", false)
      return false
    }
  }
})
