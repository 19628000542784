import { render, staticRenderFns } from "./TheFooterContainer.vue?vue&type=template&id=e8582d40&lang=pug"
import script from "./TheFooterContainer.vue?vue&type=script&lang=ts"
export * from "./TheFooterContainer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheFooter: require('/app/components/TheFooter.vue').default,LocalisedTheFooterDE: require('/app/components/localised/TheFooterDE.vue').default,LocalisedTheFooterES: require('/app/components/localised/TheFooterES.vue').default,LocalisedTheFooterFR: require('/app/components/localised/TheFooterFR.vue').default,LocalisedTheFooterIE: require('/app/components/localised/TheFooterIE.vue').default,LocalisedTheFooterIT: require('/app/components/localised/TheFooterIT.vue').default})
