// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.serviceSection_Ue7QI {\n\n    display: flex;\n\n    flex-direction: column;\n\n    gap: 9px\n}\n.serviceSection_Ue7QI > a:first-child {\n\n    margin-bottom: 4px;\n\n    border-top-width: 2px;\n\n    padding-top: 8px;\n\n    --tw-text-opacity: 1;\n\n    color: rgba(0, 0, 0, 1);\n\n    color: rgba(0, 0, 0, var(--tw-text-opacity));\n\n    text-decoration-line: none;\n\n    opacity: 0.5\n}\n.serviceSection_Ue7QI > a:first-child:hover {\n\n    text-decoration-line: none;\n\n    opacity: 0.7\n}\n.serviceSection_Ue7QI > a:first-child:hover ~ a {\n\n    opacity: 0.4\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceSection": "serviceSection_Ue7QI"
};
module.exports = ___CSS_LOADER_EXPORT___;
